<template>
  <section class="investor">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <el-row :gutter="30">
        <el-col :span="12" v-for="(item, index) in dataList" :key="index">
          <section class="investorItem">
            <router-link class="link" :to="{path: item.url, query: {id: item.id}}">
              <h2 class="title">{{ item.title }}</h2>
              <div class="line"></div>
              <p>{{ item.en }}</p>
              <el-image :src="item.img"></el-image>
            </router-link>
          </section>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  components: {imageBanner,},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/投资者关系.png'),
        slogan: require('../../assets/images/2023/investor/slogan.png'),
        class: 'investorSlogan',
      },
      dataList: [
        {title: '全部公告', en: 'Full Announcement', img: require('../../assets/images/2023/investor/1.png'), id: 1, url: '/investorDetails'},
        {title: '临时公告', en: 'Provisional Announcement', img: require('../../assets/images/2023/investor/2.png'), id: 2, url: '/investorDetails'},
        {title: '定期公告', en: 'Periodic Announcement', img: require('../../assets/images/2023/investor/3.png'), id: 3, url: '/investorDetails'},
        {title: '券商公告', en: 'Brokerage Announcement', img: require('../../assets/images/2023/investor/4.png'), id: 4, url: '/investorDetails'},
      ]
    }
  }
}
</script>

<style>
.investor {background-color: #ecf0f5; padding-bottom: 1rem;}
.investorItem {padding: 1rem; text-align: center; font-size: 1.6rem; line-height: 2;}
.investorItem .link {color: #999;}
.investorItem .link .title {color: #666;}
.investorItem .line {width: 2em; height: 2px; background-color: #e67700; margin: 0 auto;}
</style>